var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{staticClass:"water-fee-record",attrs:{"total":_vm.total,"query-info":_vm.queryInfo},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"reload-table":_vm.renderTable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('view-search-form',{attrs:{"keyword-placeholder":"姓名、订单编号","query-info":_vm.queryInfo,"tool-list":['college','major','class','grade','keyword'],"insert-select-all":[ 'college','major'],"label-width":"60px"},on:{"update:queryInfo":function($event){_vm.queryInfo=$event},"update:query-info":function($event){_vm.queryInfo=$event},"on-search":function($event){return _vm.renderTable(1)}}},[_c('el-form-item',{attrs:{"label":"宿舍楼","prop":"dormitoryId"}},[_c('el-select',{attrs:{"size":"small","filterable":""},on:{"change":_vm.changeDormBuild},model:{value:(_vm.queryInfo.dormitoryId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "dormitoryId", $$v)},expression:"queryInfo.dormitoryId"}},_vm._l((_vm.dormBuildList),function(ref){
var dormitoryName = ref.dormitoryName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":dormitoryName,"value":id}})}),1)],1),_c('el-form-item',{attrs:{"label":"楼层","prop":"floorNum"}},[_c('el-select',{attrs:{"size":"small","filterable":""},on:{"change":_vm.getDormRoomList},model:{value:(_vm.queryInfo.floorNum),callback:function ($$v) {_vm.$set(_vm.queryInfo, "floorNum", $$v)},expression:"queryInfo.floorNum"}},_vm._l((_vm.floorNumList),function(num){return _c('el-option',{key:num,attrs:{"label":("第" + num + "层"),"value":num}})}),1)],1),_c('el-form-item',{attrs:{"label":"宿舍","prop":"floorNum"}},[_c('el-select',{attrs:{"size":"small","filterable":""},model:{value:(_vm.queryInfo.roomId),callback:function ($$v) {_vm.$set(_vm.queryInfo, "roomId", $$v)},expression:"queryInfo.roomId"}},_vm._l((_vm.roomList),function(ref){
var roomName = ref.roomName;
var sexType = ref.sexType;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":(roomName + "(" + (sexType==1?'男':'女') + "生宿舍)"),"value":id}})}),1)],1)],1)]},proxy:true}])},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"type":"index","label":"ID","width":"50"}}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"110"}}),_c('el-table-column',{attrs:{"label":"日期","width":"160","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((row.updateTime||row.createTime),'YYYY-MM-DD HH:mm'))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"useMinutes","label":"用水时长","width":"90"}}),_c('el-table-column',{attrs:{"prop":"useWater","label":"已用水量","width":"90"}}),_c('el-table-column',{attrs:{"prop":"balance","label":"用水余量","width":"90"}}),_c('el-table-column',{attrs:{"prop":"className","label":"宿舍","min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房') : '')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"collegeName","label":"院系","width":"150"}}),_c('el-table-column',{attrs:{"prop":"majorName","label":"专业","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"className","label":"班级","min-width":"180"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"备注","min-width":"200px"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }