<template>
  <table-view
    class="water-fee-record"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="姓名、订单编号"
        :query-info.sync="queryInfo"
        :tool-list="['college','major','class','grade','keyword']"
        :insert-select-all="[ 'college','major']"
        @on-search="renderTable(1)"
        label-width="60px"
      >
        <el-form-item label="宿舍楼" prop="dormitoryId">
          <el-select size="small" filterable v-model="queryInfo.dormitoryId" @change="changeDormBuild">
            <el-option
              v-for="{dormitoryName, id} in dormBuildList"
              :label="dormitoryName"
              :value="id"
              :key="id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="楼层" prop="floorNum">
          <el-select size="small" filterable v-model="queryInfo.floorNum" @change="getDormRoomList">
            <el-option
              v-for="num in floorNumList"
              :label="`第${num}层`"
              :value="num"
              :key="num"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="宿舍" prop="floorNum">
          <el-select size="small" filterable v-model="queryInfo.roomId">
            <el-option
              v-for="{roomName , sexType , id} in roomList"
              :label="`${roomName}(${sexType==1?'男':'女'}生宿舍)`"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="stuName" label="姓名" width="110" />
      <el-table-column label="日期" width="160" align="center">
        <template v-slot="{row}">
          {{(row.updateTime||row.createTime)|formatDate('YYYY-MM-DD HH:mm')}}
        </template>
      </el-table-column>
      <el-table-column prop="useMinutes" label="用水时长" width="90" />
      <el-table-column prop="useWater" label="已用水量" width="90" />
      <el-table-column prop="balance" label="用水余量" width="90" />
      <el-table-column prop="className" label="宿舍" min-width="300">
        <template v-slot="{ row }">
          {{ row.roomName ? (row.dormitoryName + row.floorNum + '层' + row.roomName + '房') : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" min-width="180" />
      <el-table-column prop="className" label="班级" min-width="180" />
      <el-table-column prop="remarks" label="备注" min-width="200px" />
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageStuWaterFeeRecordApi } from '@/api/dorm/water-fee-record-api'

export default {
  name: 'waterFeeRecord',
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        grade: new Date().getFullYear(),
        classId: null,
        majorId: '',
        collegeId: '',
        dormitoryId: null,
        floorNum: null,
        roomId: null
      },
      dormBuildList: [],
      roomList: [],
      floorNumList: 0
    }
  },
  async mounted () {
    await Promise.all([
      this.renderTable(1),
      this.getRoomBuildList()
    ])
  },
  methods: {
    async renderTable (pageNum) {
      await this.getTableData(pageStuWaterFeeRecordApi, pageNum)
    },
    // 获取宿舍楼栋
    async getRoomBuildList () {
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.dormBuildList = res.data
      } catch (e) {
      }
    },
    // 选择楼栋
    changeDormBuild (dormitoryId) {
      this.queryInfo.floorNum = null
      this.queryInfo.roomId = null
      this.clearList(this.roomList)
      if (dormitoryId) {
        this.floorNumList = this.dormBuildList.filter(
          (item) => item.id === dormitoryId
        )[0].floorAmount
      }
    },
    // 获取宿舍列表
    getDormRoomList () {
      this.clearList(this.roomList)
      this.queryInfo.roomId = null
      this.$http
        .getDormRoomQuery({
          dormitoryId: this.queryInfo.dormitoryId,
          floorNum: this.queryInfo.floorNum
        })
        .then((res) => {
          this.roomList = res.data
        })
    }
  }
}
</script>
